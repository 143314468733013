@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap);
:root {
	--mainColor: rgb(247, 247, 247);;
	--mainColorLight: #5767aa;
	--secondaryColor: #b6b6b6;
	--textColor: #636262;
}
.nav-linkas:hover {
	color: white !important;
    background-color: #838383;
}
.nav-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	/* color: var(--textColor); */
	z-index: 3;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

.nav-text {
	padding: 2px;
	font-size: 20px;
	color: var(--textColor);
	margin: 0 1rem;
	text-decoration: none;
}
.nav-text:hover {
	color: var(--secondaryColor);
	/* margin: 20px; */
	background-color: rgb(238, 238, 238);
}
.nav-container .nav-btn {
	padding-bottom: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

.nav-container div,
nav {
	display: flex;
	align-items: center;
}
.navbar-logo {
  max-width: 80px;
}
@media only screen and (max-width: 600px) {

	.navbar-logo {
	}
	.nav-container .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	.nav-container nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: var(--mainColor);
		transition: all 1s ease-in-out;
	}
	.nav-container div,
	nav {
		justify-content: center;
	}
	.nav-text {
		display: flex;
		width: 100%;
		height: 3rem;
		margin: 0;
		border-radius: 5px;
		background-image: linear-gradient(0deg, rgb(243, 243, 243), rgb(255, 255, 255));
		justify-content: center;
	}
	.nav-text:hover {
		background-image: linear-gradient(0deg, rgb(243, 243, 243), rgb(223, 223, 223));
	}
	.nav-item {
		display: flex;
	}
	.nav-container .responsive_nav {
		transform: translateY(100vh);
		z-index: 2;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 1rem;
		right: 2rem;
	}


}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
    scroll-behavior: smooth;
    z-index: 1;
}

/* :root {
	--mainColor: #fcfcfc;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #383838;
} */
.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.about {
    display: flex;
    justify-content: center;
}
.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1200px;
}
.about-item {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about-local {
    display: flex;
    grid-gap: 3rem;
    gap: 3rem;
    margin-top: 2rem;
}
.about-img {
    border-radius: 50%;
    object-fit: cover;
    width: 400px;
    height: 400px;
}
.about-title {
    font-weight: 800;
}
.about-text {
    line-height: 26px;
    font-size: 17px;
}
.hr-line {
    border: 2px solid #bdbdbd;
    width: 85px;
    margin: 18px 0;
}
.background-container {
    margin-top: 2rem;
    /* max-width: 1200px; */
}

.solutions {
    display: flex;
    justify-content: center;
}
.solution-line {
    display: flex;
    justify-content: center;
}
.solution-hr-line {
    border: 2px solid #bdbdbd;
    width: 45px;
    margin-bottom: 10px;
}
.solution-container {
    background-color: #ffffff;
    padding: 3rem 0;
    display: flex;
    /* grid-template-columns: repeat(3, 1fr); */
    width: 100%;
    max-width: 1200px;
    grid-auto-flow: row;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.solution-card {
    background-color: rgb(240, 238, 238);
    width: 100%;
    max-width: 400px;
    height: 370px;
    box-shadow: rgba(190, 188, 188, 0.2) 0px 8px 24px;
    margin-bottom: 20px;
}

.solution-img {
width: 100%;
height: 240px;
object-fit: cover;
}
.solution-title {
display: flex;
justify-content: center;
margin-top: 10px;
font-size: 18px;
font-weight: bold;
}
.solution-desc {
    display: flex;
    justify-content: center;
    margin-left: 10px;
}

.contacts {
    display: flex;
    justify-content: center;
}
.contact-container {
    width: 90%;
    max-width: 1200px;
    height: auto;
    margin: 4em 0em;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    /* border-radius: 10px; */
    background: var(--nav-menu-background);
    box-shadow: 0 10px 25px rgba(92, 99, 105, .2);
}
.contact-map {
    width: 100%;
    height: auto;
    flex: 50% 1;
}
.contact-map iframe {
    width: 100%;
    /* height: 100%; */
    aspect-ratio: 1/1;
}
.contact-form {
    display: flex;
    width: 100%;
    height: auto;
    flex: 50% 1;
    padding: 25px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
}
.contact-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.contact-form h1 {
    margin-bottom: 10px;
    color: var(--text-color);
}
.btn-default {
    align-items: center;
    -webkit-appearance: none;
            appearance: none;
    background-color: white;
    border: 1px solid #dbdbdb;
    border-radius: .375em;
    box-shadow: none;
    box-sizing: border-box;
    color: var(--text-color);
    /* cursor: pointer; */
    display: inline-flex;
    /* font-size: 1rem; */
    font-size: clamp(0.8em, 1vw, 1em);
    font-weight: bold;
    height: 2.5em;
    justify-content: center;
    line-height: 1.5;
    padding: calc(.5em - 1px) 1em;
    position: relative;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
    margin: 0.5em 0em;
}
.btn-label {
    background-color: rgb(245, 245, 245);
    flex-basis: 30%;
}
.btn-value {
    flex-basis: 70%;
    /* font-size: 80%; */
    /* font-size: clamp(0.5rem, 1vw + 1rem, 1rem); */
}
.hide {
    /* display: flex; */
    visibility: hidden;
    /* right: 0; */
}
/* @media (max-width: 400px) {
    .contacts {
        width: 100%;
        height: 100%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
    .contact-container {
        width: 100%;
    }
    .contact-form {
        padding: 0;
    }
    .contacts-title {
        color: white;
    }
      .contact-map iframe {
        border-radius: 10px;
        width: 100%;
        height: 100% !important;
        max-height: 400px;
        max-width: 400px;
        margin-bottom: 10px;
      }
     
} */
/* @media (max-width: 360px) {
 .contact-label {
        flex-direction: column;
      }
    .hide {
        display: none;
    }
} */


@media only screen and (max-width: 900px) {
    .about-local {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .about-img {
        width: 90vw;
        height: 90vw;
    }
}
@media only screen and (max-width: 820px) {
    .solution-card {
        max-width: 90vw;
    }
}

.item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.item-group {
    display: flex;
    max-width: 80dvw;
    flex-direction: column;
}
.tasmota-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.block {
    min-width: 200px;
}
.comingsoon {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.time-container {
    display: flex;
    justify-content: space-around;
    font-family: sans-serif;
    text-align: center;
    padding-top: 40px;
  }
  
  .time {
    font-size: 32px;
  }
