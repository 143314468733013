@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");


:root {
	--mainColor: rgb(247, 247, 247);;
	--mainColorLight: #5767aa;
	--secondaryColor: #b6b6b6;
	--textColor: #636262;
}
.nav-linkas:hover {
	color: white !important;
    background-color: #838383;
}
.nav-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	/* color: var(--textColor); */
	z-index: 3;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

.nav-text {
	padding: 2px;
	font-size: 20px;
	color: var(--textColor);
	margin: 0 1rem;
	text-decoration: none;
}
.nav-text:hover {
	color: var(--secondaryColor);
	/* margin: 20px; */
	background-color: rgb(238, 238, 238);
}
.nav-container .nav-btn {
	padding-bottom: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

.nav-container div,
nav {
	display: flex;
	align-items: center;
}
.navbar-logo {
  max-width: 80px;
}
@media only screen and (max-width: 600px) {

	.navbar-logo {
	}
	.nav-container .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	.nav-container nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: var(--mainColor);
		transition: all 1s ease-in-out;
	}
	.nav-container div,
	nav {
		justify-content: center;
	}
	.nav-text {
		display: flex;
		width: 100%;
		height: 3rem;
		margin: 0;
		border-radius: 5px;
		background-image: linear-gradient(0deg, rgb(243, 243, 243), rgb(255, 255, 255));
		justify-content: center;
	}
	.nav-text:hover {
		background-image: linear-gradient(0deg, rgb(243, 243, 243), rgb(223, 223, 223));
	}
	.nav-item {
		display: flex;
	}
	.nav-container .responsive_nav {
		transform: translateY(100vh);
		z-index: 2;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 1rem;
		right: 2rem;
	}


}