.comingsoon {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.time-container {
    display: flex;
    justify-content: space-around;
    font-family: sans-serif;
    text-align: center;
    padding-top: 40px;
  }
  
  .time {
    font-size: 32px;
  }