.item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.item-group {
    display: flex;
    max-width: 80dvw;
    flex-direction: column;
}
.tasmota-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.block {
    min-width: 200px;
}